<template>
    <div align="center">
        <br>
        <div align="center">
            <span class="text-h4">Invia segnalazione alla Persona Operativa</span>
            <br><br>
            <hr>
        </div>
        <br><br>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">

            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-5 q-px-md" align="left">
                <q-list  separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Area:</q-item-label>
                            <q-item-label caption>{{quotazione.area}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Prodotto:</q-item-label>
                            <q-item-label caption>{{quotazione.id_prodotto}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.guid_preventivo}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Descrizione preventivo:</q-item-label>
                            <q-item-label caption>{{quotazione.nominativo}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della quotazione:</q-item-label>
                            <q-item-label caption>{{getStato}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Unit&agrave; Operativa:</q-item-label>
                            <q-item-label caption>{{quotazione.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Operatore:</q-item-label>
                            <q-item-label caption>{{quotazione.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>

            <div class="col-12 col-md-5 q-mr-sm">
                <h5 class="q-mt-sm">Inserisci il messaggio da inviare</h5>

                <q-input
                    outlined
                    square
                    name="descrizione_segnalazione"
                    v-model="descrizione_messaggio"
                    filled
                    type="textarea"
                />
                <br>

            </div>

            <div class="col-12 col-md-12">
                <hr>
            </div>

            <div class="row col-12 col-md-12 justify-center">

                <div class="col-12 col-md-6" align="center">
                    <QQButton label="TORNA AL MENU"
                        color="blue-grey"
                        icon="undo"
                        size="md"
                        icon_align="left"
                        :disable="!is_loading"
                        @click.native="onIndietroClicked()"
                    />
                </div>

                <div class="col-12 col-md-6" align="center">
                    <QQButton label="INVIA SEGNALAZIONE"
                        color="blue-grey"
                        icon="mdi-email-edit-outline"
                        size="md"
                        icon_align="right"
                        :disable="!is_loading"
                        @click.native="onInviaSegnalazioneClicked()"
                    />
                </div>


            </div>

        </div>

    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    //import policy from "@/libs/policy.js";
    import { mapFields} from "vuex-map-fields";
    import { mapState, mapActions } from "vuex";
    import commonLib from "@/libs/commonLib.js";
    //import store from "@/store";

    export default {
        name: "MenuGestioneQuotazioni",
        data() {
            return {
                quotazione: {},
                descrizione_messaggio: "",
                is_loading: true
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
            ...mapState({
                formPreventivo: state => state.formPreventivo.formPreventivo
            }),
            getStato() {
                if (commonLib.isEmpty(this.quotazione)) return "";

                return this.quotazione.stato.replaceAll("_"," ");
            }
        },
        methods: {
            ...mapActions({
                fetchDatiQuotazione: "gestioneQuotazioni/fetchDatiQuotazione",
                inviaSegnalazioneSuQuotazione: "gestioneQuotazioni/inviaSegnalazioneSuQuotazione"
            }),
            onIndietroClicked() {
                this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
            },
            async onInviaSegnalazioneClicked() {
                this.is_loading = false;
                var dati = {
                    guid_preventivo: this.quotazione.guid_preventivo,
                    id_quotazione: this.quotazione.idquotazione,
                    segnalazione: this.descrizione_messaggio
                };

                await this.inviaSegnalazioneSuQuotazione(dati);

                this.is_loading = true;

                // Torma al menu
                this.$router.push({name : "Quotazioni.MenuGestioneQuotazioni"});
            }
        },
        async mounted() {
            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
            this.descrizione_messaggio = "";
        }
    }

</script>
